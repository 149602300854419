import { graphql } from "gatsby";
import React from "react";
import ContactForm from "../components/contactForm/form";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Seo from "../components/seo";
import Title from "../components/title/title";

// markup
const ContactUsPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const title = (
    <>
      Get Your
      <br /> Commission Free
      <br /> Website Today
    </>
  );

  return (
    <>
      <Seo
        title={"Contact Us"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Title underlined={true} h1={true}>Contact Us</Title>
        <div ref={formRef}>
          <ContactForm title={title}></ContactForm>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "contact-us" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default ContactUsPage;
